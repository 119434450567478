import React, { useMemo } from "react";
import { PostLimitedResponse } from "@doczip/api-client";
import styled from "styled-components";
import LoadingCircle from "@/components/LoadingCircle";
import Carousel from "@/features/dashboard/components/common/Carousel";
import { CarouselCardProp } from "@/features/dashboard/components/common/CarouselCard";
import { isWithin7Days } from "@/features/posts/utils/calc";
import { FetchState } from "@/interfaces/fetch";
import { getUrl } from "@/utils/routes";

export enum PostType {
  Notice = "NOTICE",
  Maintenance = "MAINTENANCE",
}

const Container = styled.div`
  width: 100%;
`;

const NewSticker = styled.span`
  display: inline-block;
  margin-right: 6px;
  padding: 2px 6px;

  color: white;
  font-weight: bold;
  font-size: 10px;

  background-color: red;
  border-radius: 4px;
`;

const PostTypePrefix = styled.span`
  margin-right: 4px;
`;

interface PostCarouselProps {
  posts: PostLimitedResponse[] | null;
  postsFetchState: FetchState;
}

const PostCarousel: React.FC<PostCarouselProps> = ({ posts, postsFetchState }) => {
  const slideList = useMemo(() => {
    if (!posts) {
      return null;
    }

    const list: CarouselCardProp[] = posts.map(({ id, title, post_type, created_datetime }) => {
      const isNew = isWithin7Days(created_datetime);

      let prefixText = "";
      if (post_type === PostType.Notice) {
        prefixText = "[공지]";
      } else if (post_type === PostType.Maintenance) {
        prefixText = "[점검일정]";
      }

      return {
        descriptions: [
          isNew && <NewSticker key="new">new!</NewSticker>,

          <PostTypePrefix key="prefix">{prefixText}</PostTypePrefix>,

          <span key="title">{title}</span>,
        ],
        href: getUrl("postsDetail", { params: { postId: id } }),
      };
    });

    return list;
  }, [posts, postsFetchState]);

  return <Container>{slideList ? <Carousel slideList={slideList} /> : <LoadingCircle />}</Container>;
};

export default PostCarousel;
