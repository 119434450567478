import React from "react";

type StatusCode = "12300" | "12331" | "12344" | "12342" | "12348" | "12343" | "12347";

export const styleMapping: { [key in StatusCode]: { color: string } } = {
  "12300": { color: "#00a730" },
  "12331": { color: "#fb6945" },
  "12344": { color: "#d32f2f" },
  "12342": { color: "#d32f2f" },
  "12348": { color: "#d32f2f" },
  "12343": { color: "#d32f2f" },
  "12347": { color: "#d32f2f" },
};

interface StatusSpanProps {
  statusCode: string;
  statusText: string;
}

const getStatusSpan = ({ statusCode, statusText }: StatusSpanProps) => {
  const style = (statusCode as StatusCode) in styleMapping ? styleMapping[statusCode as StatusCode] : { color: "gray" };

  return <span style={style}>{statusText}</span>;
};

export default getStatusSpan;
