import React from "react";
import { useSelector } from "react-redux";
import PostCarousel from "@/features/posts/components/PostCarousel";
import { getLimitedPostList, getLimitedPostListFetchState } from "@/redux/posts/selectors";

const PostCarouselContainer = () => {
  const posts = useSelector(getLimitedPostList);
  const postsFetchState = useSelector(getLimitedPostListFetchState);

  return <PostCarousel posts={posts} postsFetchState={postsFetchState} />;
};

export default PostCarouselContainer;
