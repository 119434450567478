import React, { useEffect } from "react";
import { PostType } from "@doczip/api-client";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Layout from "@/components/Layout";
import DashboardAddressListWithSearch from "@/features/dashboard/components/DashboardAddressListWithSearch";
import LandingContainer from "@/features/landing/containers/LandingContainer";
import OwnersClubModal from "@/features/owners-club/components/OwnersClubModal";
import OwnersClubFreeTrialBannerContainer from "@/features/owners-club/containers/OwnersClubFreeTrialBannerContainer";
import PostCarouselContainer from "@/features/posts/containers/PostCarouselContainer";
import ReportListContainer from "@/features/reports/containers/ReportListContainer";
import SaleGradientSlideContainer from "@/features/sales/containers/SaleGradientSlideContainer";
import useWindowSize from "@/hooks/useWindowSize";
import { resetAddressList, resetCorporationAddressList } from "@/redux/address/actions";
import { resetLimitedPostListState } from "@/redux/posts/actions";
import { fetchMoreLimitedPostListThunk } from "@/redux/posts/thunk";
import { lengthsInNumbers } from "@/styles/values";

const DashboardInnerContainer = styled.div`
  padding: 27px 48px;
`;

type Props = {};

const DashboardContainer: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  useEffect(() => {
    dispatch(resetLimitedPostListState());
    dispatch(resetAddressList());
    dispatch(resetCorporationAddressList());
    dispatch(fetchMoreLimitedPostListThunk({ postTypes: [PostType.Notice, PostType.Maintenance] }));
  }, []);

  if (width < lengthsInNumbers.desktop) {
    return <LandingContainer />;
  }

  return (
    <>
      <Layout />
      <OwnersClubModal />
      <DashboardInnerContainer>
        <PostCarouselContainer />
        <DashboardAddressListWithSearch />
        <SaleGradientSlideContainer />
        <OwnersClubFreeTrialBannerContainer />
        <ReportListContainer />
      </DashboardInnerContainer>
    </>
  );
};

export default DashboardContainer;
