import React from "react";
import classnames from "classnames";
import { decamelizeKeys } from "humps";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, lengths } from "@/styles/values";
import { getUrl } from "@/utils/routes";

const FooterBox = styled.footer`
  width: 100%;
  min-width: ${lengths.sm};
  height: ${lengths.footerHeight};

  background-color: ${colors.white};

  @media screen and (max-width: ${lengths.desktop}) {
    height: ${lengths.mobileFooterHeight};
  }

  &.border-left {
    border-left: 1px solid #dfe2e7;
  }
`;

const FooterInnerBox = styled.div`
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: min(${lengths.containerWidth}, calc(100vw - 96px));
  height: 100%;
  margin: 0 auto;
  padding: 0;

  &.full-width {
    width: 100%;
    padding: 0 48px;
  }

  @media screen and (max-width: ${lengths.desktop}) {
    flex-flow: column nowrap;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
  }
`;

const FooterContentBox = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;

  @media screen and (max-width: ${lengths.desktop}) {
    flex: 0 0 auto;
    height: auto;

    line-height: 14px !important;
  }
`;

const FooterNavigationBox = styled.div`
  display: flex;
  flex: 0 0 193px;
  align-items: center;
  height: 100%;

  @media screen and (max-width: ${lengths.desktop}) {
    flex: 0 0 auto;
    height: auto;
  }
`;

const FooterContentRow = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;

  @media screen and (max-width: ${lengths.desktop}) {
    display: inline;
  }
`;

const FooterContent = styled.div`
  display: inline;
  flex: 0 0 auto;
  overflow: hidden;

  color: ${colors.dark};
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
`;

const FooterLink = styled.a`
  flex: 0 0 auto;

  color: ${colors.dark};
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-decoration: underline;

  cursor: pointer;

  transition: all 0.2s ease;

  &:hover {
    color: ${colors.dark}99;
  }
`;

const Bar = styled.div`
  flex: 0 0 auto;
  width: 1px;
  height: 9px;
  margin: 0 7px;

  border-left: 1px solid ${colors.dark};

  @media screen and (max-width: ${lengths.desktop}) {
    display: inline-block;
  }
`;

const FooterHighlightText = styled.p`
  margin: 0;
  padding-bottom: 0.25rem;

  font-weight: 600;
  font-size: 0.8rem;
`;

const FooterLightText = styled.span`
  color: #666;
`;

type Props = {
  fullWidth?: boolean;
  borderLeft?: boolean;
};

const Footer: React.FC<Props> = (props) => {
  const { fullWidth, borderLeft } = props;
  return (
    <FooterBox className={classnames(decamelizeKeys({ borderLeft }, { separator: "-" }))}>
      <FooterInnerBox className={classnames(decamelizeKeys({ fullWidth }, { separator: "-" }))}>
        <FooterContentBox>
          <FooterContentRow>
            <FooterContent>(주)제노임펙트</FooterContent>
            <Bar />
            <FooterContent>대표 최재영</FooterContent>
            <Bar />
            <FooterContent>사업자등록번호: 211-87-09238 </FooterContent>
          </FooterContentRow>
          <FooterContentRow>
            <FooterContent>주소: 서울특별시 성동구 아차산로17길 48, 1동 10층 1012-1014호(SK V1센터)</FooterContent>
            <Bar />
            <FooterContent>
              <FooterLightText>대표번호: 070-4333-4661</FooterLightText>
            </FooterContent>
          </FooterContentRow>
          <FooterContentRow>
            <FooterContent>
              <FooterHighlightText>
                고객센터 번호 010-3257-1332 (고객센터운영시간 10:00 ~ 17:00) (주말 및 공휴일 휴무)
              </FooterHighlightText>{" "}
            </FooterContent>
          </FooterContentRow>
        </FooterContentBox>
        <FooterNavigationBox>
          <FooterLink as={Link} to={getUrl("staticDetail", { params: { staticId: "terms" } })}>
            이용약관
          </FooterLink>
          <Bar />
          <FooterLink as={Link} to={getUrl("staticDetail", { params: { staticId: "privacy" } })}>
            <b>개인정보처리방침</b>
          </FooterLink>
        </FooterNavigationBox>
      </FooterInnerBox>
    </FooterBox>
  );
};

export default Footer;
